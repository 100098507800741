export default {
  "AddAddress": {
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address has been added."])},
    "adding-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding new address"])},
    "adding-personal-company-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add personal and company addresses according to the type of invoice to be issued."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorization for this operation."])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address has been saved."])}
  },
  "EditWrapper": {
    "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatories"])}
  },
  "acceptUpgradePersonalPlan": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "plan-upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade plan "])},
    "upgrade-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade account"])}
  },
  "actions": {
    "connection-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot connect to the servers."])},
    "server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred on the servers."])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access. You will be redirected to the login page."])},
    "unknown-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown function."])}
  },
  "addressCompany": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following information is missing. Please fill in the missing information. "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "tax-administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Office"])},
    "tax-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])}
  },
  "addressPersonal": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following information is missing. Please fill in the missing information. "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "tr-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship Number"])}
  },
  "apiUserAddEdit": {
    "account-a-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the account a name"])},
    "allowed-ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
    "api-user-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key Update"])},
    "api-users-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP accounts management"])},
    "app-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App password"])},
    "application-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter application name"])},
    "applicaton-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Name"])},
    "authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Code"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "citizienship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizienship No"])},
    "delete-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "enter-account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter account name"])},
    "enter-kep-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter KEP mail address"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "enter-password-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "enter-the-authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the authorized code"])},
    "enter-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it active"])},
    "kep-postal-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Postal Address"])},
    "kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Provider"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define a New API Key"])},
    "new-api-user-by-filling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new API key by filling in the information."])},
    "owner-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner User"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-sifre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "select-kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select KEP provider..."])},
    "tc-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship No"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "update-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Account Update"])},
    "update-kep-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the API key information by changing the information."])},
    "user-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API type"])}
  },
  "apiUserManagement": {
    "account-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record deleted."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add-new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "all-api-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can manage all API keys here."])},
    "allowed-ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
    "api-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key"])},
    "api-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Keys"])},
    "api-users-unauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary privileges to view API keys."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "created0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key generated."])},
    "created1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please save the key below in a safe place."])},
    "created2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to see the key again."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete API Key"])},
    "do-you-want-to-permanently-delete-the-selected-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to permanently delete the selected API key?"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "identification-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record saved successfully."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define new API key using the button above"])},
    "no-api-user-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No API key defined"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "system-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Name"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access"])},
    "undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation cannot be undone."])},
    "update-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update done\n"])},
    "you-are-not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized for this action."])},
    "you-can-define-a-new-api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the required license to define an API key."])},
    "api-user-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
  },
  "authWrapper": {
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates"])}
  },
  "authWrapperComponent": {
    "bank-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank instructions"])},
    "click-the-update-button-to-install-the-new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the update button to install the new version."])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free"])},
    "get-e-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["get e-signed"])},
    "human-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources"])},
    "new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Version Found"])},
    "new-version-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are constantly getting this warning, you can close and open your browser."])},
    "official-correspondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official Correspondence"])},
    "sign-up-in-one-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up in one step"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "your-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Documents"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "buyLicense": {
    "display-name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " user accounts"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "cancelFlow": {
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "The-process-has-been-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process has been cancelled."])},
    "cancel-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel process"])},
    "close-panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close panel"])},
    "give-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give Up"])},
    "process-cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Cancellation"])},
    "the-process-will-be-cancelled-if-payment-has-been-made-for-the-process-the-amount-paid-is-not-refundable-this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process will be cancelled. If payment has been made for the process, the amount paid is not refundable. This action cannot be undone."])},
    "the-process-will-be-deleted-along-with-all-its-documents-and-other-data-this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process will be deleted along with all its documents and other data. This action cannot be undone."])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "companyInfo": {
    "mersis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MERSİS No"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about your company."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])}
  },
  "contactAddEdit": {
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "continue-to-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes Go Delete!"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Contact"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail"])},
    "everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone in my organization"])},
    "institution-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution name"])},
    "kkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETSIS No (Institution ID code)"])},
    "mersis-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MERSİS No"])},
    "only-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only me"])},
    "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
    "select-a-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select department."])},
    "select-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select owner department."])},
    "selected-departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected department"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the contact."])},
    "sub-title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new contact."])},
    "tax-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax no"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Contact"])},
    "title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Contact"])},
    "what-are-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are contacts"])}
  },
  "contactInfo": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP address"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information."])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "tel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone 2"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication Information"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])}
  },
  "contactManagement": {
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record added"])},
    "create-new-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new contact"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record deleted"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "edit (2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "everyone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYPE"])},
    "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAME"])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no contacts"])},
    "no-record-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new contact by using the button above."])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OWNER"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All contacts you have permission to see/update."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record updated"])},
    "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Language"])},
    "preferredLanguage_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "preferredLanguage_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "contactSelector": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSTITUTION"])},
    "no-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
    "no-records-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Records Found. You can add by typing a valid KEP address."])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSON"])},
    "press-ENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press ENTER to add the written address."])},
    "the-same-record-exists-in-the-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The same record exists in the list."])},
    "write-to-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write to search..."])}
  },
  "contactSelector.detsis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETSİS"])},
  "createPassword": {
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal data is processed within the scope of the Clarification Text. By clicking the 'Create an Account' button, you confirm that you have read and accepted the Terms of Use, KVKK Clarification Text, Cookie Policy."])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie policy"])},
    "create-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your password"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set password..."])},
    "eula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Agreement"])},
    "eula-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must confirm that you accept the Terms of Use, KVKK Clarification Text and Cookie Policy."])},
    "kvkk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KVKK policy"])},
    "login-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login with your account"])},
    "or": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["or ", _interpolate(_list(0))])},
    "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all password rules are provided. Please set a password that complies with the password rules."])},
    "reenter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password again"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "your-personal-data-is-processed-within-the-scope-of-the-clarification-text-by-clicking-the-create-an-account-button-you-confirm-that-you-have-read-and-accepted-the-terms-of-use-kvkk-clarification-text-cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal data is processed within the scope of the Clarification Text. By clicking the 'Create an Account' button, you confirm that you have read and accepted the Terms of Use, KVKK Clarification Text, Cookie Policy."])}
  },
  "createSignatureImage": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "current-signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current signature"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please draw your signature."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use cursor to draw your signature to white area or upload an image."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your signature has been saved."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw your signature"])}
  },
  "creatorInfo": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit the sender information of the created e-Correspondence packages."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Information"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information updated."])}
  },
  "creditCard": {
    "16-digit-card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16 digit card number"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "billing-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Information"])},
    "billing-information-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select billing information."])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
    "ccv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCV"])},
    "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "goodsservices-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "make-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payment"])},
    "moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname"])},
    "operation-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized for this operation."])},
    "payment-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment error"])},
    "payment-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Total"])},
    "paytr-payment-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYTR did not return the result of the payment."])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment will be made via PayTR. ONAYLARIM does not store your credit card information in any way."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with Credit Card"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
  },
  "creditCardPaymentHistory": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "e-mail-sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An invoice has been sent to ", _interpolate(_named("Address")), "."])},
    "email-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Invoice"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have a payment made with a credit card"])},
    "no-receipt-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invoice has not yet been created for this payment. An e-invoice will be created and an e-mail will be sent to you as soon as possible."])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
    "no-right-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorization to view credit card transactions"])},
    "no-right-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorization for this operation."])},
    "plan-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card transactions cannot be used in this plan"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can find all your payments made by credit card"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "view-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Process"])}
  },
  "creditHistory": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BALANCE"])},
    "buy-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can buy credits using the button above"])},
    "cannot-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits cannot be purchased with this membership type."])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDIT"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "e-mail-sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An invoice has been sent to ", _interpolate(_named("Address")), "."])},
    "get-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy credit"])},
    "no-receipt-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invoice has not yet been created for this payment. An e-invoice will be created and an e-mail will be sent to you as soon as possible."])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no credit activity"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorization for this operation."])},
    "no-right-to-buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to purchase new credits."])},
    "no-rigth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authority to view credit transactions"])},
    "plan-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit movements cannot be used in this plan"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "send-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail Invoice"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find all your credit transactions here"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit transactions"])},
    "total-credit-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Credit Amount"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Process"])}
  },
  "currentLicense": {
    "add-license-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User licenses cannot be purchased on the current plan. You can switch to a pay-as-you-go plan"])},
    "additional-users-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not supported in personal accounts."])},
    "available-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Available User Accounts"])},
    "buy-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy License"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "company-trade-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Legal Name"])},
    "current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Plan"])},
    "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching records"])},
    "get-additional-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Additional User"])},
    "license-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License End Date"])},
    "license-start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Start Date"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access."])},
    "no-right-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary privileges to view the license status"])},
    "number-of-free-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Free Users"])},
    "on-premise-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premise usage"])},
    "on-premise-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not used in On-Premise deployments"])},
    "personal-account-company-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not supported in personal accounts."])},
    "personal-account-company-official-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not supported in personal accounts."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current plan and license status."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Status"])},
    "total-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total User Accounts"])},
    "total-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Users"])},
    "upgrade-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Plan"])},
    "used-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Account Used"])},
    "pades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAdES Signatures"])},
    "cades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAdES Signatures"])},
    "e-yazisma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma"])},
    "kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Module"])},
    "sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Module"])},
    "externalusers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Users"])},
    "advanced-flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Flows"])},
    "api-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SignHub API Count"])},
    "core-api-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primeAPI Count"])}
  },
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "deleteAccount": {
    "all-your-data-will-be-deleted-along-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your data will be deleted along with your account."])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "delete-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been deleted."])},
    "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account could not be deleted. Our technical team has started an investigation on the subject."])},
    "delete-my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete my account"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account and all your data in the system will be deleted. This action cannot be undone. To continue with the deletion process, type your password and press the delete account button."])},
    "this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
    "your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Password"])}
  },
  "deleteFilesOfFlow": {
    "Documents-in-the-process-were-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents in the process were deleted."])},
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "all-documents-in-the-process-will-be-deleted-this-action-cannot-be-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All documents in the process will be deleted. This action cannot be undone."])},
    "delete-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete documents"])},
    "deleting-process-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting Process Files"])}
  },
  "deleteFlow": {
    "actionundone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "process-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Deletion"])},
    "processdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process has been deleted."])},
    "processdeletionother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process will be deleted along with all its documents and other data."])}
  },
  "departmentRoleAddEdit": {
    "are-subunits-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Department Included?"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is It Active"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "select-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select user."])},
    "sub-title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create the department right."])},
    "sub-title-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the department right."])},
    "title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Department Right"])},
    "title-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Department Right"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "what-are-unit-authorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are department rights"])}
  },
  "departmentsTree": {
    "add-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add near this department"])},
    "add-under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add department below"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department added"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department deleted"])},
    "need-to-enter-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a name for department."])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no departments"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
    "renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department name has been updated."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company's departments hierarchy."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])}
  },
  "divvyDrieBrowser": {
    "add-selected-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add selected files"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "the-folder-is-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The folder is empty."])}
  },
  "divvyDriveSignIn": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "User-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "remember-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
  },
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "editHeader": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signers"])},
    "tabname-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "tabname-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "tabname-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "tabname-signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signers"])}
  },
  "enterCompanyInformation": {
    "company-trade-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Legal Name"])},
    "enter-the-following-information-to-switch-to-the-corporate-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the following information to switch to the corporate account."])},
    "you-can-write-the-full-commercial-name-of-your-company-example-seneka-software-hardware-tic-commitment-and-san-ltd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can write the full commercial name of your company. Example: Seneka Software Hardware Tic. commitment and San. Ltd."])},
    "your-company-name-is-included-in-the-application-and-in-the-e-mail-sent-for-e-signing-example-seneka-software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company name is included in the application and in the e-mail sent for e-signing. Example: Seneka Software"])}
  },
  "enterPassword": {
    "2fa-subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([" Enter the code sent to your phone for the operation named ", _interpolate(_list(0)), "."])},
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "Please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "enter-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
    "forgot-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "mail-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password reset link could not be sent to your e-mail. Please try again."])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the e-mail you entered is registered in the system, the password reset link has been sent to your e-mail address."])},
    "reset-password-send-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the e-mail you entered is registered in the system, the password reset link has been sent to your e-mail."])},
    "reset-password-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the e-mail you entered is registered in the system, the password reset link has been sent to your e-mail address."])},
    "reset-password-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the e-mail you entered is registered in the system, the password reset link has been sent to your e-mail address."])},
    "reset-password-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password reset link could not be sent to your e-mail. Please try again."])},
    "reset-password-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password reset link has been sent to your e-mail address."])},
    "tfa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two Factor Authentication"])},
    "use-for-free-by-registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use for free by registering"])}
  },
  "enterPhoneNumber": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "document-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document signed"])},
    "enter-your-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your 10-digit mobile phone number with your mobile signature defined."])},
    "ex-5xxxxxxxxxx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex: 5XXXXXXXXXX"])},
    "failed-to-get-fingerprint-value-for-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to get fingerprint value for transaction."])},
    "fingerprint-for-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fingerprint for operation is shown below. You should see the same fingerprint on your phone."])},
    "mobile-signature-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile signature process"])},
    "mobile-signing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Signing"])},
    "please-enter-a-valid-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number."])},
    "please-follow-the-instructions-on-your-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow the instructions on your phone."])},
    "sign-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Mobile"])},
    "the-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process is starting."])},
    "turk-telekom-coming-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turk Telekom"])},
    "vodafone-coming-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vodafone"])},
    "you-signed-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You signed the document."])},
    "your-e-signature-is-equivalent-to-a-wet-signature-under-the-e-signature-law-no-5070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-signature is equal to a wet signature under the law."])},
    "your-gsm-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your GSM Number"])},
    "your-gsm-operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your GSM Operator"])},
    "we-have-sent-you-an-email-regarding-the-action-you-took": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email regarding the action you took."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "an-error-occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred!"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching records"])},
  "errorHelper": {
    "ECONNABORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection aborted."])},
    "ERR_BAD_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad option."])},
    "ERR_BAD_OPTION_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad option value."])},
    "ERR_BAD_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad request."])},
    "ERR_BAD_RESPONSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad response."])},
    "ERR_CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request has been canceled."])},
    "ERR_DEPRECATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deprecated."])},
    "ERR_FR_TOO_MANY_REDIRECTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many redirects."])},
    "ERR_NETWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network error."])},
    "ETIMEDOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time out."])}
  },
  "finishJobSendKep": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail content"])},
    "receipents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])}
  },
  "flowComponent": {
    "contactType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact type"])},
    "contactPreferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred language"])},
    "notes-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes and attachments about the process"])},
    "notes-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes and Attachments"])},
    "ownerDepartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner department"])},
    "whoCanSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can see"])},
    "workingCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working company"])}
  },
  "flowContextMenu": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "create-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a copy"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete files"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
    "send-with-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send with KEP"])},
    "send-with-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send via SFTP"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
  },
  "flowDocuments": {
    "add-attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Attachment"])},
    "add-file-from-divvydrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file from DivvyDrive"])},
    "add-file-from-my-computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file from my computer"])},
    "are-you-sure-you-want-to-delete-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the document?"])},
    "at-least-one-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must upload at least one document."])},
    "authentication-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "cannot-access-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server could not be reached"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete document"])},
    "delete-error-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please delete erroneous documents."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Code:"])},
    "eyazisma-attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Correspondence Package Attachments"])},
    "eyazisma-attachments-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files that need to be added as an attachment to the e-Correspondence package."])},
    "include-documents-to-be-signed-after-adding-the-necessary-documents-you-can-proceed-to-the-next-step-with-the-next-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include documents to be signed. After adding the necessary documents, you can proceed to the next step with the next button."])},
    "invalid-upload-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The download result is invalid."])},
    "no-attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attachments have been added. You can add attachments using the button above."])},
    "no-documents-have-been-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents have been uploaded"])},
    "no-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No process"])},
    "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 page"]), _normalize([_interpolate(_named("count")), " pages"])])},
    "server-could-not-process-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server could not process the file"])},
    "session-ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session terminated"])},
    "supported-file-types-pdf-word-excel-and-powerpoint-and-image-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported file types: Pdf, Word, Excel and Powerpoint and image files."])},
    "upload-result-value-is-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The upload result is an invalid value."])},
    "wait-for-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for all documents to load."])},
    "you-can-upload-your-documents-that-you-want-signed-by-using-one-of-the-options-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload your documents that you want signed by using one of the options below."])},
    "notes-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow Notes"])},
    "notes-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add notes for others in the flow. You can add attachments to your note to share files."])},
    "ekler-ve-notlar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments and Notes"])}
  },
  "flowItemSearch": {
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
    "search-results-were-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results were not found."])}
  },
  "pagingComponent": {
    "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pages"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])}
  },
  "searchFlows": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your criterias to search."])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "last-operation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Operation Date"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "assigned-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
  },
  "flowItems": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "add-people-to-sign-the-document-the-document-will-be-sent-to-the-relevant-persons-in-the-sequence-of-steps-shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add people to sign the document."])},
    "add-people-to-sign-the-document-the-document-will-be-sent-to-the-relevant-persons-to-sign-in-the-order-shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add people to sign the document. The document will be sent to the relevant persons to sign in the order shown."])},
    "add-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Person"])},
    "add-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add signer"])},
    "add-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Step"])},
    "advanced-process-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced processes are available for <span class=\"font-semibold\">Pay-As-You-Go Personal</span> and <span class=\"font-semibold\">Pay-As-You-Go Corporate</span> accounts."])},
    "advanced-processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced processes"])},
    "advanced-processes-are-available-for-pay-as-you-go-personal-and-pay-as-you-go-corporate-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced processes are available for Pay-As-You-Go Personal and Pay-As-You-Go Corporate accounts."])},
    "are-you-sure-you-want-to-delete-the-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the signer?"])},
    "atleast-one-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There must be at least one signer."])},
    "authority-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority Level"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "create-new-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new contact"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "delete-the-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the signer"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail"])},
    "email-address-of-the-signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address of the signer"])},
    "email-and-names-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address and name and surname information must be entered for all signatories."])},
    "eyazisma-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To whom you want to send the e-correspondence package, please enter it in the field below."])},
    "eyazisma-target-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The target has been deleted."])},
    "eyazisma-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Correspondence Package Targets"])},
    "job-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item deleted."])},
    "jobs-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the process completes successfully, you can determine the actions to be taken regarding the process."])},
    "jobs-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions to be Taken When the Process is Completed"])},
    "name-and-surname-of-the-person-to-sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname of the person to sign"])},
    "no-eyazisma-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter at least one destination for the e-correspondence package."])},
    "no-eyazismatarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No destinations have been added for the e-Correspondence package. You can search from the box above or create a new record using the button below."])},
    "no-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The operation is not defined. You can define a new transaction by using the buttons below."])},
    "once-you-have-identified-the-signatories-you-can-proceed-to-the-next-step-with-the-next-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have identified the signatories, you can proceed to the next step with the next button."])},
    "operation-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Type"])},
    "send-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send with KEP"])},
    "send-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send via SFTP"])},
    "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-Mail"])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signers"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "to-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To advanced mode"])},
    "to-basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To simple mode"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "with-advanced-processes-you-can-add-more-than-one-person-in-the-same-step-and-define-transaction-types-such-as-approval-and-monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With advanced processes, you can add more than one person in the same step and define transaction types such as approval and monitoring."])},
    "you-can-visit-the-support-page-for-more-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can visit the support page for more information."])},
    "signer_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer Settings"])},
    "signer_settings_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Signer Settings for ", _interpolate(_named("signer"))])},
    "signer_settings_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set advanced settings for the selected signer."])},
    "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Signing Date"])},
    "deadline_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User must sign the document before the deadline."])},
    "flow_item_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The signer settings has been updated"])},
    "save-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save current flow"])},
    "saved-flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved flows"])}
  },
  "flowList": {
    "add-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a new process by using the button above to sign or have your documents signed."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "clear-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])},
    "create-and-view-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Creation and Monitoring"])},
    "deparment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "last-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Operation"])},
    "list-flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Listing"])},
    "no-flow-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no streams matching the selected criteria"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing found here!"])},
    "no-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process title not specified"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OWNER"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "select-departmant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Department"])},
    "select-department-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select department whose processes you want to view."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "status-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "view-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the processes of the selected department"])}
  },
  "flowMetaData": {
    "5-days-after-your-draft-documents-(if-the-process-is-not-started)-are-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 days after your draft documents (if the process is not started) are created,"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "according-to-your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to your current plan,"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
    "deletion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will be deleted on"])},
    "documents-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents deleted"])},
    "download-signed-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download e-Signed Document"])},
    "empty-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process title not specified"])},
    "flowMetaData.deletion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eee"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "owner-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner department"])},
    "process-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Information"])},
    "signature-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Signature type"])},
    "size-of-files-in-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size of the process in the system"])},
    "size-of-original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size of uploaded files"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information about flow"])},
    "verification-code-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code copied."])},
    "verification-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Method"])},
    "verify-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "you-can-upgrade-to-paid-plans-to-have-your-processes-stored-in-the-cloud-indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upgrade to paid plans to have your processes stored in the cloud indefinitely."])},
    "your-signed-documents-are-automatically-deleted-3-days-after-they-are-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your signed documents are automatically deleted 3 days after they are signed."])}
  },
  "flowMetaDataLarge": {
    "5-days-after-your-draft-documents-are-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 days after your draft documents are created,"])},
    "5-days-after-your-draft-documents-if-the-process-is-not-started-are-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 days after your draft documents (if the process is not started) are created,"])},
    "according-to- your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to your current plan,"])},
    "according-to-your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to your current plan,"])},
    "documents-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Documents Deleted)"])},
    "process-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Information"])},
    "process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information about flow"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Information"])},
    "verification-code-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code copied."])},
    "you-can-upgrade-to-paid-plans-to-have-your-processes-stored-in-the-cloud-indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upgrade to paid plans to have your processes stored in the cloud indefinitely."])},
    "your-signed-documents-are-automatically-deleted-3-days-after-they-are-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your signed documents are automatically deleted 3 days after they are signed."])}
  },
  "flowNoteItem": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "no-note-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No note entered."])}
  },
  "flowNotes": {
    "add-note-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your note..."])},
    "addattachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachment"])},
    "authenticationerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access"])},
    "cannotaccessserver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server could not be reached"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "errormessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occurred. Code: "])},
    "erroroccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occurred"])},
    "invaliduploadresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid upload result"])},
    "noflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow value is empty"])},
    "nonote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notes have been added. You can add a new note or attachment."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "servercouldnotprocessfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server could not process the file"])},
    "sessionended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session ended"])},
    "uploadresultvalueisinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The upload result is an invalid value."])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can be seen from outside the company"])}
  },
  "flowOperations": {
    "dateoperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Operation"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Null"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "transacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait"])}
  },
  "tagSelector": {
    "no-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tags"])},
    "add-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])}
  },
  "flowOperationsLarge": {
    "added-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added tags"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["approved"])},
    "cancelled-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled the process"])},
    "created-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created the process"])},
    "deleted-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted files via app"])},
    "download-signed-file-from-application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded the e-signed document (from within the application)"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "opened-sign-portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened signature portal"])},
    "person-incharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person to act"])},
    "person-to-sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person to sign"])},
    "rope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
    "send-to-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent to next person"])},
    "signed-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed"])},
    "signed-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile signed"])},
    "started-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started the process"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All transactions related to the process."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
    "user-incharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User to act"])},
    "user-to-sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User to sign"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])}
  },
  "flowOperationsTable": {
    "additional-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional data"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All transactions related to the process."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
    "transaction-maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "browser-ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser/IP"])}
  },
  "flowsComponent": {
    "arama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "cannot-create-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create a copy of the process."])},
    "copy-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A copy of the process has been created."])},
    "create-and-view-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Creation and Monitoring"])},
    "create-flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Creation"])},
    "create-for-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal process"])},
    "create-fro-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process for selected department"])},
    "create-new-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new process"])},
    "flow-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process no"])},
    "kontagin-ait-olacagi-birimi-seciniz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Owner Department"])},
    "open-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open options"])},
    "process-title-not-specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process title not specified"])},
    "processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processes"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "select-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Owner Department"])},
    "show-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced options"])},
    "sub-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are creating the process on behalf of a department, please select a department below which you've right."])},
    "sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a personal process, select the Create Personal Process option."])},
    "you-can-see-the-details-here-by-selecting-the-process-on-the-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can see the details here by selecting the process on the left."])},
    "showing-search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displaying search results"])}
  },
  "flowsWillBeDeletedReport": {
    "date-to-be-cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WILL BE DELETED ON"])},
    "date-to-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date to be deleted"])},
    "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching records"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any processes to delete anytime soon"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "process-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process No"])},
    "process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TITLE"])},
    "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processes to delete documents"])},
    "the-document-deletion-process-is-applied-to-free-accounts-and-there-is-no-document-deletion-in-the-account-you-are-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document deletion process is applied to free accounts and there is no document deletion in the account you are in."])},
    "this-feature-is-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not available"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processes to be deleted"])}
  },
  "homeView": {
    "account-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account could not be verified. Please try again."])},
    "after-completing-the-verification-process-you-can-continue-to-use-the-system-by-using-the-check-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After completing the verification process, you can continue to use the system by using the Check button."])},
    "check-it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check it"])},
    "departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])},
    "email-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email again"])},
    "failed-to-send-verification-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send verification email"])},
    "processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processes"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "signatureportal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Portal"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "verification-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation e-mail has been sent. Please check your e-mail"])},
    "we-sent-you-an-email-with-the-subject-verify-the-email-address-you-use-for-my-confirmations-you-need-to-verify-your-e-mail-address-by-clicking-the-link-in-the-e-mail-we-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you an email with the subject 'Verify the email address you use for MY CONFIRMATIONS'. You need to verify your e-mail address by clicking the link in the e-mail we sent."])},
    "you-can-use-the-send-email-again-button-to-receive-the-verification-email-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use the Send Email Again button to receive the verification email again."])},
    "your-account-has-been-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been verified."])},
    "your-e-mail-address-is-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-mail address is not verified"])}
  },
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "kepAccountAddEdit": {
    "account-a-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the account a name"])},
    "account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "app-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App password"])},
    "application-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter application name"])},
    "applicaton-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Name"])},
    "authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Code"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "citizienship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizienship No"])},
    "delete-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete KEP Account"])},
    "enter-account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter account name"])},
    "enter-kep-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter KEP mail address"])},
    "enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "enter-password-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "enter-the-authorized-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the authorized code"])},
    "enter-the-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it active"])},
    "kep-account-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP account update"])},
    "kep-accounts-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP accounts management"])},
    "kep-postal-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Postal Address"])},
    "kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Provider"])},
    "new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defining a new KEP account"])},
    "new-kep-account-by-filling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new KEP account by filling out the information."])},
    "owner-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner User"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-sifre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "select-kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select KEP provider..."])},
    "tc-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship No"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "update-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Account Update"])},
    "update-kep-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update KEP information by changing the information."])}
  },
  "kepAccountEdit.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "kepAccountManagement": {
    "account-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account deleted."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add-new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new KEP account"])},
    "all-kep-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP accounts in your institution"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete KEP account"])},
    "do-you-want-to-permanently-delete-the-selected-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to permanently delete the selected KEP account?"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "identification-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record saved successfully."])},
    "kep-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP accounts"])},
    "kep-accounts-unauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authority to view KEP accounts"])},
    "kep-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Address"])},
    "kep-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP provider"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new KEP account by using the button above"])},
    "no-kep-account-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No KEP account defined"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access"])},
    "undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation cannot be undone."])},
    "update-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update done\n"])},
    "you-are-not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized for this action."])},
    "you-can-define-a-new-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new KEP account by using the button above. Although you cannot send KEP in your current plan, this module has been left open for illustration purposes."])}
  },
  "kepAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP address"])},
  "kepReport": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "kep-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP No"])},
    "ne-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found."])},
    "no-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process title not specified"])},
    "receipents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECIPIENTS"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Again"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again to Send"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "send-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP could not be sent."])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDER ACCOUNT"])},
    "sent-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP sent successfully."])},
    "sign-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign and Send"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All KEP operations are listed below"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targets"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Report"])},
    "title-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TITLE"])},
    "title-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "unique-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process No"])},
    "need-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting to send"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
    "no-right-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authority to view the money KEP transactions."])}
  },
  "sftpReport": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "kep-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP No"])},
    "ne-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found."])},
    "no-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process title not specified"])},
    "receipents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECIPIENTS"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Again"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again to Send"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "send-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP could not be sent."])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDER ACCOUNT"])},
    "sent-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP sent successfully."])},
    "sign-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign and Send"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All SFTP operations are listed below"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targets"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Report"])},
    "title-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TITLE"])},
    "title-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "unique-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process No"])},
    "need-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting to send"])},
    "sftp-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Account"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
  },
  "languageSelector": {
    "language-change-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language setting could not be changed."])},
    "language-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language setting has been changed."])}
  },
  "licenseCalculator": {
    "1-user-account-fee-until-license-expiry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 User Account Fee Until License Expiry Date"])},
    "1-user-account-monthly-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 User Account Monthly Fee"])},
    "number-of-months-remaining-to-license-expiry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Months Remaining To License Expiry Date"])},
    "number-of-user-accounts-to-take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of User Accounts To Purchase"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "price-calc-1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("newLicenseCount")), " User Account Fee Until License Expiry Date"])},
    "productservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product/Service"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can buy additional user licenses."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Additional User License"])},
    "total-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "vat-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT (20%)"])}
  },
  "licenseCount": {
    "available-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Available User Accounts"])},
    "buy-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy License"])},
    "the-number-of-user-accounts-you-have-and-are-in-use-are-shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of user accounts you have and are in use are shown."])},
    "total-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total User Accounts"])},
    "total-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Users"])},
    "used-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Account Used"])},
    "user-accounts-usage-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User accounts usage status"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No additional user licenses are available for free plans. You can upgrade to get additional user licenses."])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail"])},
    "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail is not registered in the system"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail address"])},
    "forgot-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create a free account"])},
    "remember-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with your account"])},
    "title-for-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or continue with"])},
    "want-to-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like your documents to be e-signed free of charge by registering?"])},
    "microsoft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])}
  },
  "moneyTransfer": {
    "a-remittance-record-has-been-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wire transfer request has been created."])},
    "after-performing-the-transfer-user-accounts-will-be-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After performing the transfer, user accounts will be active."])},
    "after-you-make-the-transfer-your-credit-will-be-automatically-loaded-into-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you make the transfer, your credit will be automatically loaded into your account."])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Total"])},
    "create-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a wire transfer"])},
    "iban-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Information"])},
    "immediately-after-the-transfer-your-credit-balance-will-be-loaded-and-an-e-mail-will-be-sent-to-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediately after the transfer, your credit balance will be loaded and an e-mail will be sent to you."])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "please": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please make a wire transfer/eft to one of the following bank accounts <span class=\"font-semibold\">", _interpolate(_named("transferCode")), "</span> with description <span class=\"font-semibold\">", _interpolate(_named("priceString")), "</span> ."])},
    "receipt-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Information"])},
    "recipient-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Name"])},
    "sub-title-credit-deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you make a wire transfer or EFT, your credit is loaded into your account."])},
    "sub-title-else": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan is upgraded after you make a wire transfer or EFT."])},
    "sub-title-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User accounts will be active after making a wire transfer or EFT."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment by Wire Transfer/EFT"])},
    "when-you-create-a-remittance-record-you-will-be-sent-an-e-mail-containing-the-remittance-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you create a wire transfer request, we will send an e-mail containing the details."])},
    "your-plan-is-upgraded-after-you-make-the-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan is upgraded after you make the transfer."])}
  },
  "moneyTransferHistory": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "e-mail-sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An invoice has been sent to ", _interpolate(_named("Address")), "."])},
    "email-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail Invoice"])},
    "no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have a wire transfer/EFT record"])},
    "no-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invoice has not yet been created for this payment. An e-invoice will be created and an e-mail will be sent to you as soon as possible."])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
    "no-right-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authority to view the money order/EFT transactions."])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorization for this operation."])},
    "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
    "plan-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money transfer/EFT transactions cannot be used in this plan."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find all your money transfer/EFT records here"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Transfers"])}
  },
  "myDepartmentRights": {
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "creation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEPARTMENT"])},
    "my-dep-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Department Roles"])},
    "no-enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The department roles feature is only available on enterprise plans."])},
    "no-organization-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have corporate authorization"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The roles assigned to you for the departments are listed"])},
    "subunits-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Deps Included"])},
    "subunits-not-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Deps Not Included"])}
  },
  "myOrganizationalRights": {
    "authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "creation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
    "error-fetching-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching records"])},
    "my-corporate-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Corporate Permissions"])},
    "no-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have right"])},
    "your-corporate-authorizations-are-listed-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your corporate permissions are listed below"])}
  },
  "myProfileInfo": {
    "account-opening-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Creation Date"])},
    "account-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Type"])},
    "add-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "company-official": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Official"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this"])},
    "delete-my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete My Account"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Mail"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "my-signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My signatures"])},
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "photograph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photograph"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send password reset link"])},
    "set-as-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this"])},
    "your-information-in-the-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your information in the system"])},
    "connect-to-microsoft-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to Microsoft account"])},
    "2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two factor authentication"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noSignerApp": {
    "after-install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After completing the installation, you can click the refresh button below."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "can-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have previously installed the e-Signature tool on your computer, you can open it using the button on the side."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download-esignature-tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download e-Signature Tool"])},
    "download-for-macos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download for macOS"])},
    "download-for-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download for Windows"])},
    "esignature-tool-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Signature Tool Not Found"])},
    "install-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use the button to install the e-Signature tool on your computer."])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "open-esignature-tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open e-Signature Tool"])},
    "press-refresh-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After completing one of the options below, you can press the refresh button"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "maybeupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your signature is not found even though you have done all the steps, please make sure that the ONAYLARIM e-Signature tool is the version below. If your version is old, uninstall the application from your computer and install the new version."])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "onlyMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only me"])},
  "organizationRoleAddEdit": {
    "add-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By filling in the information, you can define an organization-wide permission for a user."])},
    "add-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Organizational Right"])},
    "continue-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Deletion!"])},
    "institutional-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Right"])},
    "is-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "update-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the record changing the information."])},
    "update-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Right Update"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "what-are-corporate-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are organizational rights"])}
  },
  "organizationRoles": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record added"])},
    "create-new-corporate-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new organizational right"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record deleted"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "feature-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutional authorization definitions can only be used by corporate accounts"])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no organizational right definitions"])},
    "no-record-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new organizational right using the button above."])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access."])},
    "no-right-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary privileges to see organizational rights"])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can manage organizational rights."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational rights"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record updated"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "payForFlow": {
    "you-can-start-the-process-by-making-a-payment-with-one-of-the-following-payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can start the process by making a payment with one of the following payment methods."])}
  },
  "paymentOptionNothing": {
    "continue-without-paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue without paying"])},
    "documents-are-deleted-3-days-after-the-completion-of-the-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents are deleted 3 days after the completion of the process."])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "start-your-process-at-no-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your process at no cost."])},
    "tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TL"])},
    "when-your-process-is-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When your process is completed, the e-signed document will be sent to you by e-mail. You can also download the e-signature document from within the system."])}
  },
  "paymentOptionPayOneTimeFee": {
    "page-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Price"])},
    "piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "service-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Fee"])},
    "signature-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Fee"])},
    "tier0-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay With Credit Card"])},
    "tier0-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a one-time payment to start the process. Pay as you need."])},
    "tier0-feature-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% VAT will be added to the price at the checkout step."])},
    "tier0-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONE TIME PAYMENT"])},
    "too-much-page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["** Since the number of pages is ", _interpolate(_named("v1")), " , the number of pages is taken as ", _interpolate(_named("v2")), " in the calculation."])},
    "unit-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])}
  },
  "paymentOptionPayWithCredit": {
    "buy-credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy credits"])},
    "insufficient-credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have enough credit. You can load credits with the Upload Credits link."])},
    "required-credit-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Credit Amount"])},
    "tier1-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with my existing credit"])},
    "tier1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instead of paying for each process, purchase credit package and pay only 10.98 TL for each process."])},
    "tier1-feature-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instead of paying for each process, you can add credit to your account and perform your transactions with a fixed fee."])},
    "tier1-feature-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this way, besides making transactions at a more affordable price, you also save time as you do not make a payment every time."])},
    "tier1-feature-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% VAT will be added to the price at the checkout step."])},
    "tier1name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAY WITH CREDIT"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instead of paying for each process, load credit and pay only "])},
    "title-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pay."])},
    "your-current-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Current Credit"])}
  },
  "personInfo": {
    "citizenshipno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship no"])},
    "duty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "persontitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
    "secondname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second name"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal information."])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])}
  },
  "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
  "register": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "email-already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail belongs to a registered user in the system."])},
    "enter-your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail address"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "sign-in-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login with your account"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "title-for-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or continue with"])}
  },
  "renameFlow": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "current-process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current process title"])},
    "new-process-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New process title"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can rename flow subject."])},
    "the-process-title-has-been-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process title has been changed."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Process Subject"])}
  },
  "renameOrganization": {
    "company-legal-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Legal Name"])},
    "company-legal-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter the company legal name."])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "company-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a company name."])},
    "company-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company name is included in the application and in the e-mail sent for e-signing. Example: Seneka Software"])},
    "compnay-legal-name-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can write the full commercial name of your company. Example: Seneka Software Hardware Inc."])},
    "legal-name-renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name changed."])},
    "renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company name has been updated."])},
    "sub-title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the company name."])},
    "sub-title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the company legal name by filling out the information."])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Company Name"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Company Legal Name"])}
  },
  "reportsView": {
    "kep-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP Report"])},
    "sftp-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Report"])},
    "module-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module Selection"])},
    "processes-to-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processes to be Deleted"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])}
  },
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mandatory)"])},
  "resetPasswordComponent": {
    "Failed-to-set-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to set password. Please try again."])},
    "Invalid-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid link. Please make sure you use the full link in the email."])},
    "Not-all-password-rules-are-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all password rules are provided. Please set a password that complies with the password rules."])},
    "Please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
    "The-password-reset-link-could-not-be-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password reset link could not be verified. Please try again."])},
    "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "Your-password-has-been-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed. You will be directed to the login page."])},
    "change-my-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change my password"])},
    "change-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your password"])},
    "login-with-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login with your account"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "new-password-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password again"])},
    "password-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both passwords entered must be the same."])},
    "password-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must have at least 1 punctuation mark"])},
    "password-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long."])},
    "password-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There must be at least one number."])},
    "password-uppercase-lowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must have at least one upper and lower case letter."])}
  },
  "rolesInDepartment": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add-new-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new role"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record created"])},
    "dont-include-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Departments are Not Included"])},
    "enterprise-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles module can only be used by corporate accounts"])},
    "include-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Departments Included"])},
    "no-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no roles defined for this department"])},
    "no-roles-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new role for the department using the button above."])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
    "reason": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This role has been created due to the role defined in <span class=\"font-semibold\">", _interpolate(_named("departmentName")), "</span> department."])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "select-department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a department from above to see the roles"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Roles in ", _interpolate(_named("departmentName")), " "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles Defined for Department"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record updated"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "scribanEditor": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
    "corrected-version-of-turkish-letters-in-the-name-of-the-first-file-uploaded-to-the-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrected version of turkish letters in the name of the first file uploaded to the process"])},
    "dd-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day value of transaction date"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation"])},
    "filename-safe-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylul_Odeme_Talimati.xlsx"])},
    "filename-safe-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrected version of Turkish letters in the name of the first file uploaded to the process"])},
    "filename-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül Ödeme Talimatı.xlsx"])},
    "filename-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the first file uploaded to the process"])},
    "hh-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time value of transaction date"])},
    "minute-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute value of transaction date"])},
    "mm-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month value of transaction date"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "september-payment-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September Payment Order.xlsx"])},
    "september-payment-order1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül Ödeme Talimatı.xlsx"])},
    "ss-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds of transaction date"])},
    "subject-safe-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maas_Odeme_Talimati"])},
    "subject-safe-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrected version of turkish letters in the subject of the process"])},
    "subject-sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maaş Ödeme Talimatı"])},
    "subject-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject of the process"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
    "test-it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test It"])},
    "unique-id-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The unique system number of the process"])},
    "yyyy-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year value of transaction date"])}
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "selectAddress": {
    "add-new-billing-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new billing information"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or add a billing address."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Information"])},
    "you-can-continue-the-process-by-choosing-one-of-your-saved-billing-information-or-you-can-create-a-new-billing-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can continue the process by choosing one of your saved billing information or you can create a new billing information."])},
    "you-do-not-have-a-defined-address-you-can-define-a-new-address-by-using-the-button-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have a defined address. You can define a new address by using the button below."])}
  },
  "selectCreditPackage": {
    "for-one-process": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"whitespace-nowrap\">", _interpolate(_named("priceForOneFlowString")), "</span> per process"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the credit package you want to purchase."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Credits"])}
  },
  "selectDepartment": {
    "action-to-be-taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action To Be Taken:"])},
    "required-authorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Authorizations:"])}
  },
  "selectPaymentMethod": {
    "credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
    "credit-deposit-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase credit with your credit card, your credit will be transferred to your account instantly."])},
    "credit-deposit-sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payments are processed by PayTR."])},
    "license-credit-card-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with your credit card and your additional user accounts will be activated instantly."])},
    "license-credit-card-sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payments are processed by PayTR."])},
    "license-money-transfer-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An e-mail will be sent to you immediately after the transfer and your additional user accounts will be activated upon confirmation of the transaction."])},
    "money-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wire Transfer"])},
    "money-transfer-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediately after the transfer, your credit balance will be loaded and an e-mail will be sent to you."])},
    "one-tim-fee-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with your credit card and your process will be started instantly."])},
    "one-tim-fee-sub-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payments are processed by PayTR."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a payment method."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "upgrade-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with your credit card and instantly switch to the plan of your choice."])},
    "upgrade-credit-card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payments are processed by PayTR."])},
    "upgrade-money-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be emailed immediately after the transfer and upon confirmation of the transaction you will be upgraded to the plan of your choice."])}
  },
  "selectPlan": {
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate"])},
    "on-premise-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premise usage"])},
    "on-premise-error-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not used in On-Premise deployments"])},
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can see your current plan and switch to other plans."])},
    "switch-to-this-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch To This Plan"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])},
    "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
    "your-current-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Current Plan"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access"])},
    "unauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary privileges to view plans."])}
  },
  "selectSignatureType": {
    "cades-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan does not support this signature type."])},
    "cades-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An e-signature that supports long-term verification is issued."])},
    "e-yazisma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Correspondence"])},
    "e-yazisma-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan does not support this signature type."])},
    "e-yazisma-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An e-correspondence package is created."])},
    "pades-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan does not support this signature type."])},
    "pades-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The documents you upload are automatically converted to PDF. Long-term PDF signature is signed."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of e-signature to be sent at the end of the process"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose e-Signature type"])}
  },
  "sendKepComponent": {
    "KEP-account-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no defined KEP account."])},
    "KEP-account-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select KEP account"])},
    "KEP-module-include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan does not include KEP module."])},
    "KEP-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP message has been saved to be sent."])},
    "Message-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the KEP message subject."])},
    "appendices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appendices"])},
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized for this transaction."])},
    "buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "e-signed-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Signed Document"])},
    "from-kep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From KEP account"])},
    "message-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Content"])},
    "only-pay-as-you-use-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Corporate Accounts can use this module."])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "save-and-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Send"])},
    "sending-KEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending with KEP"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "transaction-performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your transaction could not be performed"])},
    "you-are-not-authorized-to-send-kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to send KEP."])},
    "you-can-send-a-kep-message-by-entering-the-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can send a KEP message by entering the information."])},
    "add-detsis-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add DETSİS no to message"])}
  },
  "sendSftpComponent": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "destination-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination SFTP"])},
    "dummy-textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field will be activated in future versions."])},
    "no-SFTP-account-defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SFTP account defined"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "pay-as-you-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Corporate Accounts can use this module."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saved-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP sending operation saved."])},
    "select-SFTP-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select SFTP account..."])},
    "select-the-target-SFTP-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the target SFTP account."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "sending-with-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending with SFTP"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP sent successfully."])},
    "the-note-you-write-here-will-not-be-forwarded-to-the-bank-it-is-stored-only-as-an-institutional-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The note you write here will not be forwarded to the bank. It is stored only as an institutional record."])},
    "you-are-not-authorized-to-send-sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to send sftp."])},
    "you-can-send-an-sftp-message-by-entering-the-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can send an SFTP message by entering the information."])},
    "you-do-not-have-the-necessary-authorization-for-this-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary authorization for this operation."])},
    "your-acount-does-not-cover-the-SFTP-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan does not include the SFTP module."])}
  },
  "setDepartmentName": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Department"])},
    "delete-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the department?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Name"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename Department"])},
    "rename-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the department  a new name."])},
    "set-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Department's Name"])},
    "set-name-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of the department to be created."])},
    "unit-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Name"])},
    "units-new-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department's New Name"])},
    "you-can-use-the-button-below-to-delete-the-volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use the button below to delete the volume."])}
  },
  "setFullname": {
    "an-error-occurred-while-fetching-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while fetching information"])},
    "current-name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Name"])},
    "name-surname-determination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Your Name"])},
    "new-name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Name"])},
    "the-name-and-surname-you-entered-here-will-appear-in-the-e-mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name and surname you have entered here will appear in the e-mails of the documents you send to the relevant people for them to sign."])},
    "you-must-enter-your-name-and-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter your name and surname."])},
    "your-name-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your full name has been changed."])}
  },
  "setPhoneNumber": {
    "current-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number in the system"])},
    "new-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new mobile number"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter your mobile phone number for notifications sent to you and for two-step authentication."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Mobile Number"])},
    "your-phone-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mobile phone information has been changed."])},
    "current-gsm-operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your GSM operator in the system"])}
  },
  "setTwoFactorAuthentication": {
    "current-2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current two-factor authentication method"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New 2-Factor authentication method"])},
    "new-2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New 2-step authentication method"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is recommended to use 2-step authentication to make your account more secure."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor Authentication"])},
    "your-2fa-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your 2-step authentication method has been changed."])}
  },
  "settingsView": {
    "api-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "departments-and-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments and Roles"])},
    "eyazisma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Yazışma"])},
    "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "kep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP"])},
    "licensing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licensing"])},
    "module-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module selection"])},
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "organizational-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Rights"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "sftp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "uim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Signing"])}
  },
  "sftpTargetAddEdit": {
    "active-or-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it active"])},
    "add-new-sftp-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a new SFTP account  by filling in the information."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "destination-folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Folder"])},
    "finger-print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finger Print"])},
    "folder-creation-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Creation Format"])},
    "maximum-size-mb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Size (MB)"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name-creation-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Creation Format"])},
    "new-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new SFTP account"])},
    "open-editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open editor"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "server-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Address"])},
    "server-port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Port"])},
    "sftp-accounts-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP accounts management"])},
    "sftp-info-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the SFTP information with changing records."])},
    "sftp-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SFTP server has been connected."])},
    "stfp-account-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP account update"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test it"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "use-ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use SSL"])},
    "user-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])}
  },
  "sftpTargetManagement": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add-new-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new SFTP account"])},
    "button-define-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new SFTP account using the button above"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "define-sftp-open-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define a new SFTP account using the button above. Although you cannot send SFTP in your current plan, this module has been left open for illustration purposes."])},
    "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account deleted"])},
    "delete-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete SFTP account"])},
    "do-you-want-to-permanently-delete-the-selected-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to permanently delete the selected SFTP account?"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while getting records"])},
    "identification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record saved successfully."])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized for this operation"])},
    "no-permission-operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary permission to view SFTP accounts"])},
    "operation-undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation can not be undone."])},
    "sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP accounts"])},
    "sftp-account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP account deletion"])},
    "sftp-institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All SFTP accounts in your institution."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access"])},
    "undefined-sftp-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SFTP accounts have been defined"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])}
  },
  "appoverActionComponent": {
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your approval will be recorded with a time stamp."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to approve"])}
  },
  "approveComponent": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "document-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document signed"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
    "the-validation-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The validation process is starting."])},
    "the-verification-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification process is starting."])},
    "we-have-sent-you-an-email-regarding-the-action-you-took": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email regarding the action you took."])},
    "you-can-close-the-window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can close the window."])},
    "you-have-approved-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have approved the document."])},
    "your-transaction-has-been-recorded-with-a-timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your transaction has been recorded with a timestamp."])}
  },
  "signerAppExistsButNoCertificate": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "not-plugged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Signature card is not inserted into the computer"])},
    "plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your e-Signature into your computer."])},
    "press-refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After performing the above operations, you can press the refresh button below."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "replug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your e-Signature is attached to your computer, you can try to remove it and insert it again."])}
  },
  "signerAppExistsWithCertificates": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "choosecertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose one of the certificates below and sign the document by clicking the sign button."])},
    "enterpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIN"])},
    "esignaturesamevalidityaswetsignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-signature has the same legal validity as handwritten wet signatures."])},
    "expirationdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date: "])},
    "identificationnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship No:"])},
    "signingdocumentwithcertificatebyenteringpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can sign the document with the certificate below by entering the PIN and pressing the sign button."])},
    "signit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "youarereadytosign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are ready to sign"])},
    "multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are performing operations on multiple processes. Process titles are:"])}
  },
  "signerAppIsOld": {
    "aftercompletingtheinstallationyoucanclicktherefreshbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After completing the installation, you can click the refresh button below."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "buttontosetupesignatureoncomputer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use the side button to install the e-signature tool on your computer."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "downloadesignaturetool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download e-signature tool"])},
    "downloadformacos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download for macOS"])},
    "downloadforwindows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download for windows"])},
    "esignaturetoolisold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to install e-signature tool's new version"])},
    "presstherefreshbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After completing one of the options below, you can press the refresh button"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])}
  },
  "signerComponent": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "connectioncouldnotbeestablished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection could not be established"])},
    "documentsigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document signed"])},
    "equivalenttowetsignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-signature is equal to a wet signature under the law."])},
    "esignaturetoolcouldnotbeaccessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONAYLARIM The e-Signature Tool could not be accessed. ONAYLARIM Make sure that the e-Signature Tool is installed and running."])},
    "initiatingthesigningprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiating the signing process."])},
    "kepmessagesent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP message sent."])},
    "kepsentsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEP message sent successfully."])},
    "pleasewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])},
    "searchingforesignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for e-signature tool and e-signature cards."])},
    "sendingKep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending KEP message"])},
    "thesigningprocesscontinues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The signing process continues."])},
    "thesigningrocessiscomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The signing process is complete."])},
    "wesentyouemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you an e-mail regarding the action you took."])},
    "youcanclosethewindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can close the window"])},
    "yousignedthedocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You signed the document."])}
  },
  "signerError": {
    "anerroroccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "signerWait": {
    "pleasewait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait"])}
  },
  "signersList": {
    "Desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk"])},
    "Empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
    "Signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatories"])},
    "posted-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted Date"])},
    "signerstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer/Status"])},
    "transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Date"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait"])}
  },
  "signersListLarge": {
    "approved-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["approved on"])},
    "cannot-create-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create URL."])},
    "cannot-sent-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail could not be sent."])},
    "copy-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URL"])},
    "e-mail-request-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The e-mail sending operation has been received."])},
    "get-transaction-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URL to clipboard"])},
    "not-yet-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet sent"])},
    "passed-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passed on"])},
    "re-send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-Mail Again"])},
    "refused-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refused on"])},
    "sent-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent on"])},
    "sign-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Now"])},
    "signed-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed on"])},
    "signer-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons to sign documents and statuses"])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signers"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "trade-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Now"])},
    "url-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL copied."])}
  },
  "summaryForFreePlans": {
    "after-you-sign-the-document-the-e-signed-document-will-be-sent-to-you-by-e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you sign the document, the e-signed document will be sent to you by e-mail."])},
    "give-the-process-a-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give The Process A Name"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "process-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process name"])},
    "since-you-are-on-the-free-plan-the-documents-will-be-deleted-5-days-after-the-process-is-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since you are on the free plan, the documents will be deleted 3 days after the process is completed."])},
    "start-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start process"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information you enter will be displayed in the e-mail sent to the signers."])},
    "the-e-signed-document-will-be-sent-to-you-by-e-mail-when-all-signatories-have-signed-the-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The e-signed document will be sent to you by e-mail when all signatories have signed the documents."])},
    "the-name-you-give-to-the-process-will-appear-in-the-e-mail-sent-to-the-signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name you give to the process will appear in the e-mail sent to the signatories."])},
    "the-note-you-wrote-will-be-attached-to-the-e-mail-sent-to-the-signatories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The note you wrote will be attached to the e-mail sent to the signatories."])},
    "the-process-is-starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process is starting..."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Information"])},
    "write-a-note-for-signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a Note for Signers"])},
    "write-your-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your note..."])},
    "your-process-will-be-started-free-of-charge-and-an-e-mail-will-be-sent-to-the-people-you-designate-to-sign-in-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your process will be started free of charge and an e-mail will be sent to the people you designate to sign in order."])}
  },
  "summaryGeneral": {
    "i-will-process-later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will process later"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "process-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process started"])},
    "processnotstarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This process can not be started."])},
    "processnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign now"])},
    "started-with-process-but-error-occured-while-sending-email-to-contacts-error-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started with process but error occured while sending email to contacts. Error detail:"])},
    "would-you-like-to-e-sign-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to e-sign documents?"])}
  },
  "templateDesigner": {
    "5070-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document has been signed with qualified electronic signature."])},
    "5070-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document can be verified at http://v.onaylarim.com with the code 1234567-8901234567890123456789012345678901234567890."])},
    "confirm-delete-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove the signature field from the document?"])},
    "confirm-delete-freetextbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove the text box?"])},
    "data-matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kod"])},
    "delete-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete signature field"])},
    "delete-freetextbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete text box"])},
    "document-can-be-downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document can be downloaded"])},
    "document-can-be-downloaded-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document can also be downloaded while verifying the document"])},
    "document-cannot-be-downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document cannot be downloaded"])},
    "document-cannot-be-downloaded-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity of the document can be verified, but the document cannot be downloaded"])},
    "document-dont-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document cannot be downloaded and no confirmation on page"])},
    "document-dont-use-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity of the document can be verified, but the document cannot be downloaded. And there will be no confirmation box on document."])},
    "document-validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Validation"])},
    "document-verification-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Verification Method"])},
    "drag-and-drop-signers-onto-the-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop signers onto the document"])},
    "ensure-all-fields-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you place all the signers on the document and that the two signature fields do not overlap."])},
    "field-same-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The same signer can be added to the document only once."])},
    "identifying-signature-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifying Signature Fields"])},
    "qr-code-help-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you add a data matrix on the document, there is a data matrix on the signed document. When this QR code is read by smart phones, the page where the document and the e-signatures on it are confirmed is opened."])},
    "qr-help-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a QR code is not mandatory."])},
    "qr-help-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-datacode documents can also be verified with the verification information at the bottom of the document."])},
    "qr-help-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can adjust the location of the QR code by moving the QR code image."])},
    "qr-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify whether the document will have a QR code or not."])},
    "signature-help-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The signature field of each person who will sign the document must be placed somewhere on the document."])},
    "signature-help-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each signer is indicated in a different colour. You have to drag and drop the signature box of each person onto the document."])},
    "signature-help-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change the places of the signature fields added on the document with drag and drop."])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signers"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "use-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use QR Code"])},
    "use-qr-code-false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't use QR Code"])},
    "verify-help-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons who receive the e-signed document can confirm validity of this document and the e-signatures on it, with the QR code on the document or the verification information at the bottom of the document."])},
    "verify-help-1": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["If you want the confirming person to be able to download the e-signed document along with the confirmation of the document, you should choose option 2, ( ", _linked("templateDesigner.document-can-be-downloaded"), " )"])},
    "verify-help-2": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize(["If you do not want the confirmer to be able to download the e-signed document, you must select option 1, ( ", _linked("templateDesigner.document-cannot-be-downloaded"), " )"])},
    "verify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify the validation method of the document"])}
  },
  "templatesFile": {
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])}
  },
  "this-feature-is-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not available"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "userAccountComponent": {
    "change-name-and-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change my name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting"])},
    "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected"])},
    "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "name-not-entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name not entered"])},
    "no-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Connection"])},
    "reconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnecting"])},
    "set-name-and-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set name and surname"])},
    "the-connection-is-disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The connection is disconnecting"])},
    "unknown-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown status"])}
  },
  "userAccountSmall": {
    "change-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change name and surname"])},
    "no-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Connection"])},
    "no-name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name not entered"])},
    "set-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set name and surname"])},
    "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])}
  },
  "userAddEdit": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "citizenship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship no"])},
    "delete-the-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the account"])},
    "e-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "is-it-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it active"])},
    "name-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name surname"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "subtitle-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a new user by filling in the information."])},
    "subtitle-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can update the user information."])},
    "title-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New User"])},
    "title-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update User"])},
    "type-the-users-citizenship-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the user's TC"])},
    "type-the-users-email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the user's email address"])},
    "type-the-users-first-and-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the user's first and last name"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "user-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])}
  },
  "usersManagement": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record added"])},
    "delete-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user account has been deleted."])},
    "deletion-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete user account."])},
    "do-you-want-to-permanently-delete-the-selected-user-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to permanently delete the selected user account?"])},
    "do-you-want-to-send-a-password-reset-email-to-the-selected-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send a password reset email to the selected user?"])},
    "no-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found in system"])},
    "no-right-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access."])},
    "no-right-error-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary privileges to view users"])},
    "no-undo-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
    "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "reset-password-mail-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password reset email has been sent to the user."])},
    "reset-password-mail-sent-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send password reset email to user."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users in your organization."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record updated"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "user-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deletion"])},
    "user-password-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User password reset"])}
  },
  "verifyEmailComponent": {
    "Email-could-not-be-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email could not be verified. Please try again."])},
    "Invalid-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid link. Please make sure to use the full link in the email."])},
    "login-to-the-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to the system"])},
    "verify-your-e-mail-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your e-mail address"])},
    "verifying-your-e-mail-address-please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying your e-mail address, please wait."])},
    "your-e-mail-address-has-been-verified-you-can-continue-your-transactions-by-logging-into-the-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-mail address has been verified. You can continue your transactions by logging into the system."])}
  },
  "version": {
    "api-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Version"])},
    "auth-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization Service"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
  },
  "viewFlow": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes and Attachments"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "process-monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process monitoring"])},
    "signers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signers"])},
    "viewpdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])}
  },
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "yeni-suerec-olustur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new flow"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
}